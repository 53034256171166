const Homeproduct =
[
    // Special
    { 
        id: 1,
        Name: 'abstract patterned fit',
        price: '69000',
        image: 'image/top1.jpeg',
        image2: 'image/top1b.jpeg',
        cat: 'special',
        type: 'special01',
        p: 'Abstract patterned Shirt paired with White Trousers. Available in S M L XL XXL',
    },
    { 
        id: 1,
        Name: 'abstract patterned fit',
        price: '69000',
        image: 'image/new (1).jpeg',
        image2: 'image/new (8).jpeg',
        image3: 'image/new (9).jpeg', 
        cat: 'special',
        type: 'special',
        p: 'Abstract patterned Shirt paired with White Trousers. Available in S M L XL XXL',
    },
    { 
        id: 1,
        Name: 'abstract patterned fit',
        price: '69000',
        image: 'image/new (3).jpeg',
        image2: 'image/new (2).jpeg',
        cat: 'special',
        type: 'special',
        p: 'Abstract patterned Shirt paired with White Trousers. Available in S M L XL XXL',
    },
    { 
        id: 1,
        Name: 'abstract patterned fit',
        price: '69000',
        image: 'image/new (4).jpeg',
        image2: 'image/new (7).jpeg',
        cat: 'special',
        type: 'special',
        p: 'Abstract patterned Shirt paired with White Trousers. Available in S M L XL XXL',
    },
    { 
        id: 1,
        Name: 'abstract patterned fit',
        price: '69000',
        image: 'image/new (6).jpeg',
        image2: 'image/new (5).jpeg',
        cat: 'special',
        type: 'special',
        p: 'Abstract patterned Shirt paired with White Trousers. Available in S M L XL XXL',
    },
    {
        id: 2,
        Name: 'cutton tees',
        price: '50000',
        image: 'image/top9.jpeg',
        cat: 'special',
        type: 'special02',
        p: 'details',
    },
    {
        id: 3,
        Name: 'white linen',
        price: '55000',
        image: 'image/top3.jpeg',
        cat: 'special',
        type: 'special03',
        p: 'details',
    },
    {
        id: 4,
        Name: 'custom linen',
        price: '69000',
        image: 'image/top4.jpeg',
        image2: 'image/top4a.jpeg',
        image3: 'image/top4b.jpeg',
        cat: 'special',
        type: 'special',
        p: 'details',
    },
    {
        id: 5,
        Name: 'linen full-fit',
        price: '90000',
        image: 'image/top5.jpeg',
        cat: 'special',
        type: 'special',
        p: 'details',
    },
    {
        id: 6,
        Name: 'linen',
        price: '55000',
        image: 'image/top6.jpeg',
        cat: 'special',
        type: 'special',
        p: 'details',
    },
    {
        id: 7,
        Name: 'paired fit',
        price: '100000',
        image: 'image/top7.jpeg',
        image2: 'image/top7b.jpeg',
        cat: 'special',
        type: 'special',
        p: 'Brown linen shirt paired with a cream linen trouser(s,m,l,xl,xxl)',
    },
    {
        id: 8,
        Name: 'cotton tee',
        price: '55000',
        image: 'image/top8.jpeg',
        cat: 'special',
        type: 'special',
        p: 'details',
    },
    {
        id: 9,
        Name: 'efs-fullfit brown',
        price: '42000',
        image: 'image/full.jpeg',
        image2: 'image/full brown (1).jpeg',
        image3: 'image/full brown (2).jpeg',
        image4: 'image/full brown (3).jpeg',
        cat: 'full fit',
        type: 'special',
        p: 'details',
    },
    {
        id: 10,
        Name: 'efs C-shorts ',
        price: '35000',
        image: 'image/cargo.jpeg',
        cat: 'speaker',
        type: 'shorts',
        p: 'cargo shorts',
    },
    {
        id: 11,
        Name: 'efs',
        price: '45000',
        image: 'image/topB3.jpeg',
        cat: 'speaker',
        type: 'special',
        p: 'details',
    },
    {
        id: 12,
        Name: 'efs',
        price: '58000',
        image: 'image/topB4.jpeg',
        cat: 'speaker',
        type: 'special',
        p: 'details',
    },
    {
        id: 13,
        Name: 'efs cargo',
        price: '48000',
        image: 'image/cargo-t.jpeg',
        cat: 'speaker',
        type: 'pants',
        p: 'details',
    },
    {
        id: 14,
        Name: 'Afritailore',
        price: '55000',
        image: 'image/topB6.JPG',
        cat: 'speaker',
        type: 'special',
        p: 'details',
    },   
    {
        id: 15,
        Name: 'Afritailore',
        price: '70000',
        image: 'image/topB7.jpeg',
        cat: 'speaker',
        type: 'special',
        p: 'details',
    },
    {
        id: 16,
        Name: 'Afritailore',
        price: '55000',
        image: 'image/topB8.jpeg',
        cat: 'speaker',
        type: 'special',
        p: 'details',
    },
    {
        id: 17,
        Name: 'fullfit cream',
        price: '32000',
        image: 'image/full white (2).jpeg',
        image2: 'image/full white (1).jpeg',
        image3: 'image/full white (3).jpeg',
        image4: 'image/full white (4).jpeg',
        cat: 'speaker',
        type: 'special',
        p: 'Blended polyester 2-piece',
    },
    {
        id: 18,
        Name: 'efs',
        price: '35000',
        image: 'image/bishop collar (1).jpeg',
        image2: 'image/bishop collar (2).jpeg',
        image3: 'image/bishop collar (3).jpeg',
        image4: 'image/bishop collar (4).jpeg',
        cat: 'speaker',
        type: 'special',
        p: '100% linen bishop collar shirt. Double stitched for that premium soft boy look.',
    },
    
    
    // shirt
    {
        id: 1,
        Name: 'featured-top',
        price: '16000',
        image: 'image/t-shirt (3).jpeg',
        image2: 'image/t-shirt (4).jpeg',
        image3: 'image/t-shirt (1).jpeg',
        image4: 'image/t-shirt (6).jpeg',
        cat: 'shirt',
        type: 'top',
        p: 'details',
    },
    {
        id: 2,
        Name: 'urban',
        price: '26000',
        image: 'image/urban (1).jpeg',
        image2: 'image/urban (2).jpeg',
        image3: 'image/urban (3).jpeg',
        image4: 'image/urban (4).jpeg',
        image5: 'image/urban (5).jpeg',
        cat: 'shirt',
        type: 'top',
        p: 'details',
    }, 
    {
        id: 3,
        Name: 'Male Crop Top',
        price: '30000',
        image: 'image/croptop.jpeg',
        cat: 'shirt',
        type: 'top',
        p: 'details',
    },
    {
        id: 4,
        Name: 'Urban polo',
        price: '30000',
        image: 'image/urban polo (1).jpeg',
        image2: 'image/urban polo (2).jpeg',
        cat: 'shirt',
        type: 'top',
        p: 'details',
    },

    // Jean
    {
        id: 1,
        Name: 'zipper-jean',
        price: '27000',
        image: 'image/jean (1).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 2,
        Name: 'AMs-jean',
        price: '27000',
        image: 'image/jean (2).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 3,
        Name: 'jean',
        price: '27000',
        image: 'image/jean (3).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 4,
        Name: 'Jorts',
        price: '20000',
        image: 'image/jorts (1).jpeg',
        image2: 'image/jorts (2).jpeg',
        image3: 'image/jorts (3).jpeg',
        image4: 'image/jorts (4).jpeg',
        image5: 'image/jorts (5).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 5,
        Name: 'jean',
        price: '27000',
        image: 'image/jean (5).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 6,
        Name: 'jean',
        price: '27000',
        image: 'image/jean (6).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 7,
        Name: 'jean',
        price: '27000',
        image: 'image/jean (20).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 8,
        Name: 'jean',
        price: '27000',
        image: 'image/jean (8).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 9,
        Name: 'jean',
        price: '807',
        image: 'image/jean (9).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 10,
        Name: 'jean',
        price: '807',
        image: 'image/jean (10).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 11,
        Name: 'jean',
        price: '807',
        image: 'image/jean (11).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },    
    {
        id: 12,
        Name: 'jean',
        price: '807',
        image: 'image/jean (12).jpeg',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },
    {
        id: 13,
        Name: 'jean',
        image: 'image/jean (4).jpeg',
        price: '27000',
        cat: 'jean',
        type: 'jean',
        p: 'details',
    },
    //fullfit
    {
        id: 1,
        Name: 'Leather fit B/B',
        price: '76000',
        image: 'image/full-fit (1).jpeg',
        cat: 'fullfit',
        type: 'fullfit',
        p: 'details',
    },    
    {
        id: 2,
        Name: 'Leather fit B/C',
        price: '76000',
        image: 'image/full-fit (2).jpeg',
        cat: 'fullfit',
        type: 'fullfit',
        p: 'details',
    },
    {
        id: 3,
        Name: 'Leather fit B/R',
        image: 'image/full-fit (3).jpeg',
        price: '76000',
        cat: 'fullfit',
        type: 'fullfit',
        p: 'details',
    },  
    {
        id: 4,
        Name: 'full fit cotton',
        image: 'image/fullfitB (1).jpeg',
        image2: 'image/fullfitB (2).jpeg',
        image3: 'image/fullfitB (3).jpeg',
        price: '32000',
        cat: 'fullfit',
        type: 'fullfit',
        p: 'details',
    },
]
export default Homeproduct